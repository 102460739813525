import styled from "styled-components";
import { ArticleCard } from "./ArticleCard";
import { BREAKPOINTS, FONT_SIZE } from "../themes";
import { ContentWrapper } from "./ContentWrapper";
import { AppSubtitle, AppTitle } from "./Typography";
import { ENewsTag, ESortOptions } from "../enums/enums";
import { Tag } from "./Tag";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getShortPublishedArticlesByCategory } from "../api/apiService";

const FiltersWrapper = styled.div`
  margin-bottom: 2rem;
  padding-left: 1rem;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SortTitle = styled(AppSubtitle)`
  margin: 0;
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const SortOption = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${({ $isSelected }) => $isSelected ? 800 : 400};
  text-decoration: underline;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.sm};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${FONT_SIZE.md};
  }
`;

const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;

  @media (min-width: ${BREAKPOINTS.sm}) {
    gap: 2rem;
  }
`;

const newsTags = Object.keys(ENewsTag).filter(key => key !== '0').map(key => ({ label: ENewsTag[key], key: key }));
const sortLabels = {
  [ESortOptions[2]]: 'Newest',
  [ESortOptions[1]]: 'Oldest',
};
const sortOptions = [
  {
    label: sortLabels[ESortOptions['2']],
    key: '2',
  },
  {
    label: sortLabels[ESortOptions['1']],
    key: '1',
  },
];

const NewsFeed = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState('0');
  const [selectedSort, setSelectedSort] = useState('2');
  const [news, setNews] = useState([]);

  useEffect(() => {
    const urlFilter = searchParams.get('filter');
    const urlSort = searchParams.get('sort');

    urlFilter && setSelectedFilter(urlFilter);
    urlSort && setSelectedSort(urlSort);
  }, []);

  useEffect(() => {
    getShortPublishedArticlesByCategory(selectedFilter, selectedSort, 100, 0).then(res => setNews(res));
  }, [searchParams]);

  return (
    <ContentWrapper>
      <AppTitle>Filters</AppTitle>
      <FiltersWrapper>
        <AppSubtitle>Tags</AppSubtitle>
        <Tags>
          {newsTags.map(tag => (
            <Tag
              key={`newsFilter_${tag.label}`}
              $isHighlighted={selectedFilter === tag.key}
              onClick={() => {
                if (selectedFilter === tag.key) {
                  setSelectedFilter('0');
                  setSearchParams({
                    sort: selectedSort,
                  });
                } else {
                  setSelectedFilter(tag.key);
                  setSearchParams({
                    filter: tag.key,
                    sort: selectedSort,
                  });
                }
              }}
            >
              {tag.label}
            </Tag>
          ))}
        </Tags>
        
        <SortWrapper>
          <SortTitle>Sort by:</SortTitle>
          {sortOptions.map(option => (
            <SortOption
              key={`newsSort_${ESortOptions[option.key]}`}
              $isSelected={selectedSort === option.key}
              onClick={() => {
                setSelectedSort(option.key);
                setSearchParams({
                  ...(selectedFilter === '0' ? {} : { filter: selectedFilter }),
                  sort: option.key,
                });
              }}
            >
              {option.label}
            </SortOption>
          ))}
        </SortWrapper>
      </FiltersWrapper>
      <AppTitle>Blogs</AppTitle>
      <ArticlesWrapper>
        {news.map((article) => (
          <ArticleCard key={article.newsArticleId} {...article} />
        ))}
      </ArticlesWrapper>
    </ContentWrapper>
  );
};

export { NewsFeed };
