import styled from 'styled-components';
import logo from '../assets/LogoHorizontal.png';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Logo = () => {
  return (
    <StyledWrapper>
      <StyledImage src={logo} alt="NTG Logo" />
    </StyledWrapper>
  );
};

export { Logo };
