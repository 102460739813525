import styled from "styled-components";
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";

export const Tag = styled.div`
  padding: 0.75rem 1rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 5px;
  background-color: ${({ $isHighlighted }) => $isHighlighted ? COLOR.gray : COLOR.grayBlueDark};
  cursor: pointer;
  
  @media (min-width: ${BREAKPOINTS.sm}) {
    padding: 0.5rem 1rem;
    font-size: ${FONT_SIZE.sm};
    border-radius: 10px;
  }
`;
