import { FaPlaystation, FaSteam, FaXbox } from "react-icons/fa";
import { EGame, EPlatform } from "../enums/enums";

export const platformIcon = {
  [EPlatform[1]]: <FaSteam />,
  [EPlatform[2]]: <FaPlaystation />,
  [EPlatform[3]]: <FaXbox />
};

const gitdScreenshots = Array(8).fill('').map((elem, index) => require(`../assets/GitDScreenshots/${index + 1}.png`));

export const gameMeta = {
  [EGame[1]]: {
    friendlyName: 'gitd',
    title: 'Greedy in the Dark',
    description: "<b>Greedy in the dark</b> is a slow-paced platformer game. You venture into the dark caves under an abandoned factory to loot stuff. Of course, you didn't bring any equipment for navigation in the dark (it's pricey these days), but you are 100% sure that there will be something to exchange it for.",
    demoDescription: "The release date of Greedy in the Dark, is yet to be announced, in the meantime feel free to check our <b>Demo</b> version on <a href='https://store.steampowered.com/app/2641340/Greedy_in_the_Dark/' target='_blank'><b><u>Steam</u></b></a>, as well make sure to wishlist it and you will be notified once it will be released.<br/><br/> After completing the <b>Demo</b>, you can check your completion time result in our Leaderboard (make sure you have Submit time setting checked in game)",
    genres: ["Adventure", "2D Platformer", "Non-Linear", "Single Player"],
    tags: ["2"],
    heroImage: require("../assets/GitDHero.png"),
    heroLabelImage: require("../assets/GitDLogo.png"),
    coverImage: require("../assets/GitDCover.png"),
    discordBanner: require("../assets/GitDDiscordBanner.png"),
    discordBannerTitle: "Join our discord",
    discordBannerSubtitle: "To be notified about GitD news",
    screenshots: gitdScreenshots,
    youtubeLink: "",
  }
};