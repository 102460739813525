import React from "react";
import styled from "styled-components";
import { AppSubtitle, AppText } from "./Typography";
import { Tag } from "./Tag";
import { EGame, ENewsTag, EPlatform } from "../enums/enums";
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";
import { Link } from "react-router-dom";
import {platformIcon} from "../hardcode/gameMeta";
import { PlatformLink } from "./PlatformLink";
import { PATHS } from "../appConfig";

const Container = styled.div`
  @media (min-width: ${BREAKPOINTS.sm}) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    column-gap: 2rem;
  }
`;

const ImageLink = styled(Link)`
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.sm}) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    margin: 0;
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const MainInfo = styled.div`
  @media (min-width: ${BREAKPOINTS.sm}) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
`;

const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.sm}) {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    align-self: flex-end;
  }
`;

const BottomInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;

  @media (min-width: ${BREAKPOINTS.md}) {
    margin: 0 0 1rem;
  }
`;

const BottomInfo = styled.div`

`;

const Platforms = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 2rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: 3rem;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
`;

const GameLink = styled(Link)`
  padding: 1rem;
  padding-top: 1.325rem;
  font-size: ${FONT_SIZE.md};
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;
  background-color: ${COLOR.grayBlueDark};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${COLOR.gray};
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    align-self: flex-start;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${FONT_SIZE.lg};
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    margin-top: auto;
  }
`;

const GameCard = ({
  gameId,
  friendlyName,
  platforms,
  availability,
  metadata,
}) => {
  return (
    <Container>
      <ImageLink to={`${PATHS.games}/${friendlyName}`}>
        <Image src={metadata.coverImage} alt={`${EGame[gameId]} cover`} />
      </ImageLink>
      <MainInfo>
        <AppSubtitle>{metadata.title}</AppSubtitle>
        <AppText dangerouslySetInnerHTML={{ __html: metadata.description }} />
      </MainInfo>
      <SecondaryInfo>
        <BottomInfoWrapper>
          <BottomInfo>
            <AppSubtitle>Platforms</AppSubtitle>
            <Platforms>
              {platforms.map(({ platformId, storeLink }) => (
                <PlatformLink key={`gameCard_${gameId}_${platformId}`} to={storeLink} target="_blank">
                  {platformIcon[EPlatform[platformId]]}
                </PlatformLink>
              ))}
            </Platforms>
          </BottomInfo>
          <BottomInfo>
            <AppSubtitle>Blogs tag</AppSubtitle>
            <TagsWrapper>
              {metadata.tags.map(tag => (
                <Link key={`${EGame[gameId]}_tag_${tag}`} to={`${PATHS.blog}?filter=${tag}`}>
                  <Tag>{ENewsTag[tag]}</Tag>
                </Link>
              ))}
            </TagsWrapper>
          </BottomInfo>
        </BottomInfoWrapper>
        <GameLink to={`/games/${friendlyName}`}>
          Game page
        </GameLink>
      </SecondaryInfo>
    </Container>
  );
};

export { GameCard };
