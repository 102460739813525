import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {AppInsightsContext, ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById('root'));

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_SITE_APPINSIGHTS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});

appInsights.loadAppInsights();

root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
        <App />
    </AppInsightsContext.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
