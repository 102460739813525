export const EGame = Object.freeze({
  1: 'GITD',
});

export const EGameAvailability = Object.freeze({
  1: 'None',
  2: 'ComingSoon',
  3: 'Scheduled',
  4: 'Released',
});

export const EPlatform = Object.freeze({
  1: 'Steam',
  2: 'PSN',
  3: 'XboxLive',
});

export const ENewsTag = Object.freeze({
  0: 'None',
  1: 'general',
  2: 'gitd',
});

export const ESortOptions = Object.freeze({
  1: 'DateAsc',
  2: 'DateDesc',
});
