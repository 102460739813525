import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import MainScreen from "./screens/MainScreen";
import ArticleScreen from "./screens/ArticleScreen";
import ContactScreen from "./screens/ContactScreen";
import NewsScreen from "./screens/NewsScreen";
import { PATHS } from "./appConfig";
import { PageWrapper, SocialBar } from "./components";
import GameScreen from "./screens/GameScreen";
import GamesScreen from "./screens/GamesScreen";
import {getAvailableGames, getGameById, getGameByName} from "./api/apiService";
import { Footer } from "./components/Footer";

const Layout = () => {
  return (
    <PageWrapper>
      <SocialBar />
      <Outlet />
      <Footer />
    </PageWrapper>
  );
};

const loader = (loaderFunction) => async ({ params }) => {
  return await loaderFunction(params ?? {});
};

const fetchGames = async () => {
  const data = await getAvailableGames();
  if (data) return data;
};

const fetchGameData = async (params) => {
  const data = await getGameByName(params.id);
  if (data) return data;
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Navigate to={PATHS.main} />,
    children: [
      {
        path: PATHS.main,
        element: (
          <MainScreen />
        ),
      },
      {
        path: PATHS.article,
        element: (
          <ArticleScreen />
        ),
      },
      {
        path: PATHS.about,
        element: (
          <ContactScreen />
        ),
      },
      {
        path: PATHS.blog,
        element: (
          <NewsScreen />
        ),
      },
      {
        path: PATHS.games,
        loader: loader(fetchGames),
        element: (
          <GamesScreen />
        ),
      },
      {
        path: PATHS.game,
        loader: loader(fetchGameData),
        element: (
          <GameScreen />
        ),
      },
    ],
  },
]);

export const Router = () => {
  return (
    <RouterProvider router={router} />
  );
};
