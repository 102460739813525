export const COLOR = {
  grayDark: '#1e1e21',
  grayBlueDark: '#2b2d38',
  gray: '#555760',
  grayLight: '#2b2d38',
  grayVeryDark: '#0b0b0b',
  shadow: '#151516',
  black: '#000000',
  white: '#ffffff',
  carouselDarkBG: '#030408',
  gold: '#dcc657',
  red: '#FF0000'
};

export const FONT_SIZE = {
  xs: '0.75rem',
  sm: '1.125rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '2.75rem',
  xxl: '3.25rem',
};

export const BREAKPOINTS = {
  sm: '600px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};
