import styled from "styled-components";
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";
import { useEffect, useState } from "react";
import { getLeaderboardCount, getLeaderboardPage } from "../api/apiService";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";

const Container = styled.div`
  background-color: ${COLOR.shadow};
  border-radius: 10px;
  overflow: hidden;
`;

const Control = styled.div`
  padding: 1rem 2rem 0;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: ${FONT_SIZE.md};
  font-weight: 800;

  @media (min-width: ${BREAKPOINTS.sm}) {
    flex-direction: row;
    font-size: ${FONT_SIZE.md};
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${FONT_SIZE.sm};
  }

  @media (min-width: ${BREAKPOINTS.xl}) {
    font-size: ${FONT_SIZE.md};
  }
`;

const Pagination = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0.5rem;
  font-size: ${FONT_SIZE.md};
  font-weight: 800;
`;

const PaginationArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  color: ${({ $isActive }) => $isActive ? COLOR.white : COLOR.gray};
  background-color: ${COLOR.grayLight};
  border-radius: 5px;
  cursor: ${({ $isActive }) => $isActive ? 'pointer' : 'not-allowed'};
  user-select: none;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${({ $isActive }) => $isActive ? COLOR.gray : COLOR.grayLight};
  }
`;

const PaginationNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  padding-top: 0.375rem;
  background-color: ${COLOR.grayLight};
  border-radius: 5px;
  user-select: none;
`;

const Labels = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr 5fr;
  gap: 1rem;
  padding: 1rem 0 0.5rem;
  font-size: ${FONT_SIZE.xs};
`;

const LeaderboardRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr 5fr;
  gap: 1rem;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
`;

const RowField = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ $highlighted }) => $highlighted ? COLOR.gold : 'inherit'};
`;

const RowsWrapper = styled.div`
  font-size: ${FONT_SIZE.xs};

  ${LeaderboardRow}:nth-child(odd) {
    background-color: ${COLOR.grayVeryDark};
  }

  ${LeaderboardRow}:nth-child(even) {
    background-color: ${COLOR.black};
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.sm};
  }
`;

const AdminLabel = styled.label`
  color: ${COLOR.gold};
`;

const PAGE_SIZE = 10;
const emptyArray = Array(PAGE_SIZE).fill(null);

const Leaderboard = ({
  gameId,
}) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const appInsightsContext = useAppInsightsContext();

  useEffect(() => {
    getLeaderboardCount().then(res => {
      setTotalPages(Math.ceil(res / PAGE_SIZE))
    });
  }, [gameId]);

  useEffect(() => {
    getLeaderboardPage(page, PAGE_SIZE).then(res => setRows(res));
  }, [gameId, page]);

  const formattedRows = [...rows, ...emptyArray].slice(0, PAGE_SIZE);
  
  return (
    <Container>
      <Control>
        <Head>
          <div>Leaderboard</div>
          <Pagination>
            <PaginationArrow
              $isActive={page > 1}
              onClick={() => {
                page > 1 && setPage(prev => prev - 1);
                appInsightsContext.trackEvent({name: 'LEADERBOARD_INTERACTION'}, {pageNumber: page});
              }}
            >
              <MdArrowBackIosNew />
            </PaginationArrow>
            <PaginationNumber>
              {page}
            </PaginationNumber>
            <PaginationArrow
              $isActive={page < totalPages}
              onClick={() => {
                page < totalPages && setPage(prev => prev + 1);
                appInsightsContext.trackEvent({name: 'LEADERBOARD_INTERACTION'}, {pageNumber: page});
              }}
            >
              <MdArrowForwardIos />
            </PaginationArrow>
          </Pagination>
        </Head>
        <Labels>
          <div>#</div>
          <div>Name</div>
          <div>Time</div>
        </Labels>
      </Control>
      <RowsWrapper>
        {formattedRows.map((row, index) => (
          <LeaderboardRow key={row?.playerId || `empty_${index}`}>
            <RowField>{(page - 1) * PAGE_SIZE + index + 1}</RowField>
            <RowField $highlighted={row?.isAdmin}>{row?.playerName}</RowField>
            <RowField>{row?.timeView}</RowField>
          </LeaderboardRow>
        ))}
      </RowsWrapper>
    </Container>
  );
};

export { Leaderboard };
