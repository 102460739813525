import React from "react";
import { useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { AppTitle, ContentWrapper, GameCard } from "../components";
import { BREAKPOINTS } from "../themes";
import { gameMeta } from "../hardcode/gameMeta";
import { EGame } from "../enums/enums";
import {useTrackPageView} from "../hooks/UseTrackPageView";

const GamesGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  
  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 1.625rem;
  }
`;

const GamesScreen = () => {
  const games = useLoaderData();

  useTrackPageView('GAMES');

  return (
    <ContentWrapper>
      <AppTitle>Our games</AppTitle>
      <GamesGrid>
        {games.map(game => (
          <GameCard
            key={`game_${game.gameId}`}
            friendlyName={game.friendlyName}
            gameId={game.gameId}
            platforms={game.platforms}
            availability={game.availability}
            metadata={gameMeta[EGame[game.gameId]]}
          />
        ))}
      </GamesGrid>
    </ContentWrapper>
  );
};

export default GamesScreen;
