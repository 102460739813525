import styled from 'styled-components';
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";
import { HEADER_LINKS, PATHS, SOCIAL_LINKS } from '../appConfig';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import { useEffect, useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: ${({ $isOpen }) => $isOpen ? '100%' : 'auto'};
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;

  @media (min-width: ${BREAKPOINTS.md}) {
    height: auto;
  }
`;

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.black};

  @media (min-width: ${BREAKPOINTS.md}) {
    height: 6rem;
  }
`;

const Content = styled.div`
  display: block;
  width: 100%;
  max-width: 95rem;
  padding: 0;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  font-size: 2rem;
`;

const LogoLink = styled(Link)`
  height: 3rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    width: 8rem;
    height: auto;
  }
`;

const MenuIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: none;
  }
`;

const HeaderLinkContainer = styled.div`
  display: ${({ $isOpen}) => $isOpen ? 'flex' : 'none'};
  flex-direction: column;
  font-size: ${FONT_SIZE.md};
  font-weight: 800;
  line-height: 1.25rem;
  text-transform: uppercase;
  border-top: 2px solid ${COLOR.grayDark};
  transition: all 0.3s;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    font-size: ${FONT_SIZE.sm};
    border: none;
  }
`;

const HeaderLinkText = styled.div`
  padding: 1.375rem 2rem 1rem;
  text-decoration: ${({ $isCurrent }) => $isCurrent ? 'underline' : 'none'};
  border-bottom: 2px solid ${COLOR.grayDark};
  transition: all 0.3s;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding: 0;
    border: none;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const SocialLinkContainer = styled.div`
  display: ${({ $isOpen }) => $isOpen ? 'flex' : 'none'};
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  font-size: 2.5rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    font-size: 2rem;
  }
`;

const SocialLink = styled(Link)`
  display: flex;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const SocialBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <Overlay $isOpen={isOpen} onClick={(e) => {
      e.stopPropagation();
      setIsOpen(false);
    }}>
      <Bar>
        <Content>
          <LogoWrapper>
            <LogoLink to={PATHS.main}>
              <Logo />
            </LogoLink>
            <MenuIconWrapper onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(prev => !prev)
            }}>
              <GiHamburgerMenu />
            </MenuIconWrapper>
          </LogoWrapper>
          <HeaderLinkContainer $isOpen={isOpen}>
            {HEADER_LINKS.map((link) => (
              <Link key={link.label} to={link.path}>
                <HeaderLinkText $isCurrent={link.path === location.pathname}>{link.label}</HeaderLinkText>
              </Link>
            ))}
          </HeaderLinkContainer>
          <SocialLinkContainer $isOpen={isOpen}>
            {SOCIAL_LINKS.filter(link => link.label && link.href).map(link => (
              <SocialLink key={link.label} to={link.href} target='_blank'>{link.icon}</SocialLink>
            ))}
          </SocialLinkContainer>
        </Content>
      </Bar>
    </Overlay>
  );
};

export { SocialBar };
