import axios from "axios";

const apiUrl = process.env.REACT_APP_ApiUrl ?? 'https://www.namethinkgergames.com/api';
const newsApi = `${apiUrl}/news`;
const gamesApi = `${apiUrl}/games`;
const gitdDemoLeaderboardApi = `${apiUrl}/GITDDemoLeaderboard`;

export const getShortPublishedArticles = async () => {
    const response = await axios.get(`${newsApi}/all`);
    return response.data;
}

export const getShortPublishedArticlesByCategory = async (category, sortDirection, take, skip) => {
    const response = await axios.get(`${newsApi}/${category}?sortDirection=${sortDirection}&skip=${skip}&take=${take}`);
    return response.data;
}

export const getArticleById = async (id) => {
    const response = await axios.get(`${newsApi}/article?articleId=${id}`);
    return response.data;
}

export const getAvailableGamesShort = async () => {
    const response = await axios.get(`${gamesApi}/availableShort`);
    return response.data;
}

export const getAvailableGames = async () => {
    const response = await axios.get(`${gamesApi}/available`);
    return response.data;
}

export const getGameById = async (gameId) => {
    const response = await axios.get(`${gamesApi}/byId?gameId=${gameId}`);
    return response.data;
}

export const getGameByName = async (gameName) => {
    const response = await axios.get(`${gamesApi}/byName?gameName=${gameName}`);
    return response.data;
}

export const getLeaderboardCount = async() => {
    const response = await axios.get(`${gitdDemoLeaderboardApi}/count`);
    return response.data;
}

export const getLeaderboardPage = async (pageNumber, pageSize) => {
    const take = pageSize;
    const skip = (pageNumber - 1) * pageSize;
    
    const response = await axios.get(`${gitdDemoLeaderboardApi}/entries?skip=${skip}&take=${take}`);
    return response.data;
}
