import styled from "styled-components";
import { EMAIL, CONTACT_LINKS } from "../appConfig";
import { Link } from "react-router-dom";
import { AppSubtitle, AppText, AppTitle } from "../components/Typography";
import { useTrackPageView } from "../hooks/UseTrackPageView";
import { BREAKPOINTS, FONT_SIZE } from "../themes";
import {ContentWrapper, PlatformLink} from "../components";

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
    gap: 2rem;
  }
`;

const Logo = styled.img`
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
`;

const Team = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: ${BREAKPOINTS.sm}) {
    flex-direction: row;
    justify-content: start;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const MemberText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MemberName = styled(AppSubtitle)`
  margin-bottom: 0;
`;

const MemberTitle = styled(AppText)`
  padding: 0;
`;

const Avatar = styled.img`
  width: 100px;

  @media (min-width: ${BREAKPOINTS.md}) {
    width: 60%;
    max-width: 300px;
  }
`;

const Contacts = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-size: 3rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    gap: 2rem;
    padding-left: 1rem;
    font-size: 4rem;
  }
`;

const Mail = styled.div`
  font-size: ${FONT_SIZE.xs};

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 1rem;
  }
`;

const MailText = styled.div`
  margin-bottom: 0.25rem;
  font-size: ${FONT_SIZE.sm};
  text-decoration: underline;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${FONT_SIZE.md};
  }
`;

const team = [
  {
    name: 'AltRunner',
    title: 'Lead Developer',
    imageSrc: require("../assets/avatar1.png"),
  },
  {
    name: 'Regularshterh',
    title: 'Lead Game Designer',
    imageSrc: require("../assets/avatar3.png"),
  },
];

const ContactScreen = () => {
  useTrackPageView('CONTACTUS');
  
  return (
    <Wrapper>
      <Head>
        <div>
          <AppTitle>About</AppTitle>
          <AppText>
              We’re a rising player in the indie gaming industry, with a team of seasoned professionals who’ve honed their skills at leading AAA studios. Drawing on our expertise and passion, we’re committed to delivering an exceptional gaming experience that will leave a lasting impact.
          </AppText>
        </div>
        <Logo src={require("../assets/LogoHorizontalHead.png")} />
      </Head>
      <AppTitle>Team</AppTitle>
      <Team>
        {team.map(member => (
          <Member key={member.name}>
            <Avatar src={member.imageSrc} />
            <MemberText>
              <MemberName>{member.name}</MemberName>
              <MemberTitle>{member.title}</MemberTitle>
            </MemberText>
          </Member>
        ))}
      </Team>
      <AppTitle>Contacts</AppTitle>
      <Contacts>
        {CONTACT_LINKS.map(link => (
          <PlatformLink key={link.label} target='_blank' to={link.href}>
            {link.icon}
          </PlatformLink>
        ))}
      </Contacts>
      <Mail>
        Feel free to contact us:
        <MailText>
        <Link target='_blank' to={`mailto:${EMAIL}`}>{EMAIL}</Link>
        </MailText>
      </Mail>
    </Wrapper>
  );
};

export default ContactScreen;
