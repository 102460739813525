import { FaDiscord, FaYoutube, FaSteam, FaTiktok } from 'react-icons/fa';

export const PATHS = {
  main: '/',
  article: '/article/:id',
  games: '/games',
  game: '/games/:id',
  blog: '/blog',
  about: '/about',
};

export const EXTERNAL_LINKS = {
  DISCORD: 'https://discord.gg/makTBabCaq',
  YOUTUBE: 'https://www.youtube.com/@NameThinkerGames',
  STEAM: 'https://store.steampowered.com/search/?developer=NameThinkerGames',
  TIKTOK: 'https://www.tiktok.com/@namethinkergames',
};
export const EMAIL = 'contactus@namethinkergames.com';

export const SOCIAL_LINKS = [
  {
    label: 'Discord',
    href: EXTERNAL_LINKS.DISCORD,
    icon: <FaDiscord />,
  },
  {
    label: 'Youtube',
    href: EXTERNAL_LINKS.YOUTUBE,
    icon: <FaYoutube />,
  },

];

export const CONTACT_LINKS = [
  ...SOCIAL_LINKS,
  {
    label: 'Steam',
    href: EXTERNAL_LINKS.STEAM,
    icon: <FaSteam />,
  },
  {
    label: 'Tiktok',
    href: EXTERNAL_LINKS.TIKTOK,
    icon: <FaTiktok />,
  }
];

export const HEADER_LINKS = [
  {
    label: 'Home',
    path: PATHS.main,
  },
  {
    label: 'Games',
    path: PATHS.games,
  },
  {
    label: 'Blog',
    path: PATHS.blog,
  },
  {
    label: 'About',
    path: PATHS.about,
  },
];
