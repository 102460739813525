import styled from 'styled-components';
import { BREAKPOINTS, COLOR } from "../themes";

export const PageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  background-color: ${COLOR.grayDark};
  color: ${COLOR.white};

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-top: 6rem;
  }
`;
