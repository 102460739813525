import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { GoSquare, GoSquareFill } from "react-icons/go";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

const Wrapper = styled.div`
  border-radius: ${({ $isRounded }) => $isRounded ? '10px' : '0'};
  overflow: hidden;
`;

const IndicatorContainer = styled.div`
  display: inline-block;
  font-size: 1rem;
  user-select: none;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ $isLeft }) => $isLeft ? 0 : 'unset'};
  right: ${({ $isLeft }) => $isLeft ? 'unset' : 0};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  user-select: none;
  cursor: pointer;
`;

const Indicator = (
  onClickHandler,
  isSelected,
) => {
  
  return (
    <IndicatorContainer onClick={onClickHandler}>
      {isSelected ? <GoSquareFill /> : <GoSquare />}
    </IndicatorContainer>
  );
};

const ArrowBack = (
  onClickHandler,
) => {
  return (
    <ArrowContainer onClick={onClickHandler} $isLeft>
      <MdArrowBackIosNew />
    </ArrowContainer>
  );
};

const ArrowForward = (
  onClickHandler,
) => {
  return (
    <ArrowContainer onClick={onClickHandler}>
      <MdArrowForwardIos />
    </ArrowContainer>
  );
};

const AppCarousel = ({
  children,
  isRounded,
  ...carouselProps
}) => {
  return (
    <Wrapper $isRounded={isRounded}>
      <Carousel
        infiniteLoop
        {...carouselProps}
        renderIndicator={Indicator}
        renderArrowPrev={ArrowBack}
        renderArrowNext={ArrowForward}
      >
        {children}
      </Carousel>
    </Wrapper>
  );
};

export { AppCarousel };
