import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";
import { EXTERNAL_LINKS } from "../appConfig";
import { AppSubtitle, AppTitle } from "./Typography";
import { Link } from "react-router-dom";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 5rem;
  margin: 2rem 0;
  background-color: ${COLOR.black};
  border-radius: 10px;
  overflow: hidden;
  user-select: none;

  @media (min-width: ${BREAKPOINTS.sm}) {
    height: 7rem;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    height: 10rem;
  }
`;

const Image = styled.img`
  height: 100%;
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1rem;
  padding-right: 3rem;
  background: linear-gradient(90deg, black 0%, black 60%, transparent 100%);

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 2rem;
  }
`;

const Title = styled(AppTitle)`
  margin: 0;
  font-size: ${FONT_SIZE.sm};
  
  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.lg};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${FONT_SIZE.xl};
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${FONT_SIZE.xxl};
  }
`;

const Subtitle = styled(AppSubtitle)`
  margin: 0;
  font-size: ${FONT_SIZE.xs};

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.sm};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${FONT_SIZE.md};
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${FONT_SIZE.lg};
  }
`;

const Banner = ({
  src="",
  alt="",
  title="",
  subtitle=""
}) => {
  return (
    <Link to={EXTERNAL_LINKS.DISCORD}>
      <Container>
        <Image src={src} alt={alt} />
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
      </Container>
    </Link>
  );
};

export { Banner };
