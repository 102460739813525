import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import {
  AppCarousel,
  AppSubtitle,
  AppText,
  AppTitle,
  Banner,
  ContentWrapper,
  Leaderboard,
  PlatformLink,
  Tag
} from "../components";
import { BREAKPOINTS, COLOR } from "../themes";
import { gameMeta, platformIcon } from "../hardcode/gameMeta";
import { EGame, ENewsTag, EPlatform } from "../enums/enums";
import { PATHS } from "../appConfig";
import {useTrackPageView} from "../hooks/UseTrackPageView";

const Wrapper = styled(ContentWrapper)`
  position: relative;
  z-index: 1;
`;

const Hero = styled.div`
  position: relative;
  height: 30rem;
  background-color: ${COLOR.black};

  @media (min-width: ${BREAKPOINTS.md}) {
    display: flex;
    justify-content: flex-end;
    height: unset;
    max-height: 60vh;
  }
`;

const HeroImage = styled.img`
  width: 100%;
  height: 50vh;
  min-height: 100%;
  object-fit: cover;
  object-position: center;

  @media (min-width: ${BREAKPOINTS.md}) {
    width: 60%;
    height: 100%;
    max-height: 60vh;
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 95rem;
  height: 100%;
  background: none;

  @media (min-width: ${BREAKPOINTS.md}) {
    justify-content: flex-start;
    background: linear-gradient(90deg, black 0%, black 40%, transparent 60%);
  }
`;

const HeroLogo = styled.img`
  width: 80%;
  max-width: 50rem;
  height: 80%;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.md}) {
    margin-left: 10%;
  }
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    grid-template-columns: 3fr 2fr;
    gap: 2rem;
  }
`;

const Description = styled.div`
  @media (min-width: ${BREAKPOINTS.md}) {
    grid-row: 1;
    grid-column: 1 / 2;
  }
`;

const Details = styled.div`
  margin-top: -5rem;
  padding: 1rem 1.5rem;
  background-color: ${COLOR.black};
  border-radius: 10px;

  @media (min-width: ${BREAKPOINTS.md}) {
    grid-row: 1;
    grid-column: 2 / 3;
    padding: 2rem 2.5rem;
  }
`;

const Subtitle = styled(AppSubtitle)`
  margin: 1rem 0 0.5rem;
  padding-left: 1rem;
`;

const ScreenshotContainer = styled.img`
  border-radius: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding-left: 1.5rem;
  font-size: 1.5rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 2rem;
    font-size: 2rem;
  }
`;

const TagGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  padding-left: 1rem;
`;

const DemoSection = styled.div`
  @media (min-width: ${BREAKPOINTS.lg}) {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 2rem;
  }
`;

const DemoDescription = styled.div`
  padding-bottom: 1rem;

  @media (min-width: ${BREAKPOINTS.lg}) {
    padding-bottom: 0;
  }
`;

const DemoVideo = styled.div`

`;

const GameScreen = () => {
  const gameData = useLoaderData();

  useTrackPageView(`Game/${gameData.gameId}`);

  const gameId = gameData.gameId;
  const metadata = gameMeta[EGame[gameId]];

  return (
    <div>
      <Hero>
        <HeroImage src={metadata.heroImage} alt="GitD Background" />
        <Gradient>
          <HeroLogo src={metadata.heroLabelImage} alt="GitD Logo" />
        </Gradient>
      </Hero>
      <Wrapper>
        <DetailsWrapper>
          <Details>
            <AppTitle>Details</AppTitle>
            <Subtitle>Platforms</Subtitle>
            <IconContainer>
              {gameData.platforms.map(({ platformId, storeLink }) => (
                <PlatformLink key={`platform_${platformId}`} to={storeLink} target="_blank">
                  {platformIcon[EPlatform[platformId]]}
                </PlatformLink>
              ))}
            </IconContainer>
            <Subtitle>Genres</Subtitle>
            <AppText>
              {metadata.genres.join(', ')}
            </AppText>
            <Subtitle>Release</Subtitle>
            <AppText>
              {metadata.releaseDate || 'TBA'}
            </AppText>
            <Subtitle>Blogs tag</Subtitle>
            <TagGrid>
              {metadata.tags.map(tag => (
                <Link key={`tag_${ENewsTag[tag]}`} to={`${PATHS.blog}?filter=${tag}`}>
                  <Tag>{ENewsTag[tag]}</Tag>
                </Link>
              ))}
            </TagGrid>
          </Details>
          <Description>
            <AppTitle>Description</AppTitle>
            <AppText dangerouslySetInnerHTML={{ __html: metadata.description }} />
          </Description>
        </DetailsWrapper>
        <AppCarousel
          isRounded
          autoPlay={true}
          showArrows={true}
          showThumbs={false}
          showStatus={false}
        >
          {metadata.screenshots.map(screen => <ScreenshotContainer key={screen} src={screen} alt="screenshot" />)}
        </AppCarousel>
        <Banner
          title={metadata.discordBannerTitle}
          subtitle={metadata.discordBannerSubtitle}
          src={metadata.discordBanner}
          alt="Discord banner"
        />
        <DemoSection>
          <DemoDescription>
            <AppTitle>Demo</AppTitle>
            <AppText dangerouslySetInnerHTML={{__html: metadata.demoDescription}}/>
            <DemoVideo></DemoVideo>
          </DemoDescription>
          <Leaderboard />
        </DemoSection>
      </Wrapper>
    </div>
  );
};

export default GameScreen;
