import styled from "styled-components";
import { BREAKPOINTS, FONT_SIZE } from "../themes";

export const AppTitle = styled.div`
  margin-bottom: 1rem;
  font-size: ${FONT_SIZE.md};
  font-weight: 900;
  line-height: 1.25;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.lg};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    margin-bottom: 2rem;
    font-size: ${FONT_SIZE.xl};
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const AppSubtitle = styled.div`
  margin-bottom: 0.75rem;
  font-size: ${FONT_SIZE.sm};
  font-weight: 800;
  line-height: 1.25;

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.md};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    margin-bottom: 1.5rem;
    font-size: ${FONT_SIZE.lg};
  }
`;

export const AppText = styled.div`
  padding-left: 1rem;
  font-size: ${FONT_SIZE.xs};
  font-weight: 400;
  line-height: 1.25;

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${FONT_SIZE.sm};
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 2rem;
  }
`;
