import { NewsFeed } from "../components";
import {useTrackPageView} from "../hooks/UseTrackPageView";

const NewsScreen = () => {
  useTrackPageView('NEWS');
  
  return (
    <NewsFeed />
  );
};

export default NewsScreen;
