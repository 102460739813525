import styled from "styled-components";
import { COLOR, FONT_SIZE } from "../themes";
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding: 0 1rem;
  background-color: ${COLOR.black};
`;

const Content = styled.div`
  width: 100%;
  max-width: 95rem;
  padding: 1rem 0;
`;

const Left = styled.div`

`;

const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const LanguageCode = styled.div`
  font-size: ${FONT_SIZE.sm};
  font-weight: 800;
  text-decoration: underline;
  text-transform: uppercase;
`;

const Copyright = styled.div`
  font-size: ${FONT_SIZE.xs};
`;

const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <Left>
          <Language>
            <div>Language</div>
            <LanguageCode>EN</LanguageCode>
          </Language>
          <Copyright>
            Copyright © {moment().format('YYYY')} NameThinkerGames
            <br/>
            All rights reserved
          </Copyright>
        </Left>
      </Content>
    </Wrapper>
  );
};

export { Footer };
