import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS } from '../themes';
import moment from 'moment';
import { Tag } from './Tag';
import { AppSubtitle, AppText } from './Typography';
import { PATHS } from '../appConfig';
import { ENewsTag } from '../enums/enums';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  transition: all 0.2s;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.md}) {
    width: 24rem;
  }
`;

const TopText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ShortText = styled(AppText)`
  padding: 0;
`;

const LinkText = styled(AppSubtitle)`
  margin-top: 1rem;
  margin-bottom: 0;
  text-decoration: underline;

  @media (min-width: ${BREAKPOINTS.md}) {
    margin-top: auto;
  }
`;

const ArticleCard = ({
  newsArticleId,
  title,
  subtitle,
  category,
  createdAt,
  thumbnail,
}) => {
  return (
    <Wrapper>
      <AppSubtitle>{title}</AppSubtitle>
      <Content>
        <ImageWrapper>
          <Link to={`/article/${newsArticleId}`}>
            <ArticleImage src={thumbnail} alt={title} />
          </Link>
        </ImageWrapper>      
        <TextWrapper>
          <TopText>
            <ShortText>{moment(createdAt).format('DD-MM-YYYY')}</ShortText>
            {category ? (
              <Link to={`${PATHS.blog}?filter=${category}`}>
                <Tag>{ENewsTag[category]}</Tag>
              </Link>
            ) : null}
          </TopText>
          <ShortText>{subtitle}</ShortText>
          <LinkText>
            <Link to={`/article/${newsArticleId}`}>Read more</Link>
          </LinkText>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};

export { ArticleCard };
