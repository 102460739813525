import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import styled from "styled-components";
import moment from 'moment';
import { getArticleById } from "../api/apiService";
import { EXTERNAL_LINKS } from "../appConfig";
import { BREAKPOINTS } from "../themes";
import {useTrackPageView} from "../hooks/UseTrackPageView";


const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90rem;
  margin: 1rem auto 0;
  padding: 0 0.75rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding: 0 2rem;
  }
`

const CreatedAt = styled.div`
  align-self: flex-end;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  transition: all 0.3s;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: 2rem;
  }
`;

const ArticleFooter = styled.div`
  margin: 3rem 0;

  @media (min-width: ${BREAKPOINTS.md}) {
    margin-top: 5rem;
  }
`;

const emptyArticle = {
  createdAt: '',
  title: '',
  subtitle: '',
  content: '',
};

const ArticleScreen = () => {
  const [article, setArticle] = useState(emptyArticle);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  let { id } = useParams();

  useTrackPageView(`News/${id}`);

  useEffect(() => {
    if (id) {
      getArticleById(id).then(res => {
        const itemText = EditorState.createWithContent(convertFromRaw(JSON.parse(res.content)));
        setEditorState(itemText);
        setArticle(res);
      });
    }
  }, [id]);

  return (
    <ArticleContainer>
      <CreatedAt>{moment(article.createdAt).format('DD-MM-YYYY')}</CreatedAt>
      <Title>{article.title}</Title>
      <Editor
        editorState={editorState}
        toolbarClassName='editorToolbar'
        editorClassName='editorContent'
        readOnly={true}
      />
      <ArticleFooter className='editorContent'>
        <span>You can discuss this article and share any feedback on our </span>
        <Link to={EXTERNAL_LINKS.DISCORD}>Discord server</Link>
        <span>!</span>
      </ArticleFooter>
    </ArticleContainer>
  );
};

export default ArticleScreen;
