import { useEffect, useState } from "react";
import styled from 'styled-components';
import { getShortPublishedArticles } from "../api/apiService";
import { BREAKPOINTS, COLOR, FONT_SIZE } from "../themes";
import { AppCarousel, AppTitle, ArticleCard, ContentWrapper } from "../components";
import { Link } from "react-router-dom";
import {useTrackPageView} from "../hooks/UseTrackPageView";

const LatestArticles = styled.div`
  padding-top: 2rem;
`;

const CarouselWrapper = styled.div`
  background-color: ${COLOR.carouselDarkBG};
`;

const CarouselContent = styled.div`
  position: relative;
  max-width: 95rem;
  margin: 0 auto;
`;

const CarouselLink = styled.div`
  position: absolute;
  top: 100%;
  left: 1rem;
  right: 1rem;
  transform: translate(0, -20%);
  z-index: 20;
  padding: 1rem;
  padding-top: 1.325rem;
  font-size: ${FONT_SIZE.md};
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;
  background-color: ${COLOR.grayBlueDark};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${COLOR.gray};
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    right: unset;
    text-align: left;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    left: 3.5rem;
    transform: translate(0, -50%);
    font-size: ${FONT_SIZE.lg};
  }

  @media (min-width: ${BREAKPOINTS.xl}) {
    left: 50%;
    transform: translate(-34rem, -50%);
  }
`;

const CarouselItem = styled.div`
  @media (min-width: ${BREAKPOINTS.lg}) {
    height: 20rem;
  }
`;

const CarouselImage = styled.img`
  object-fit: contain;
  display: ${({ $isMobile }) => $isMobile ? 'block' : 'none'} !important;
  width: 100%;
  height: 100%;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: ${({ $isMobile }) => $isMobile ? 'none' : 'block'} !important;
  }
`;

const Shadow = styled.div`
  width: 100%;
  height: 5rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
`;

const ArticleGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-left: 0rem;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding-left: 1.625rem;
  }
`;

const MainScreen = () => {
  const [latestArticles, setLatestArticles] = useState([]);

  const gameId = 1;
  const gameName = 'gitd';
  
  useTrackPageView('MAIN');

  useEffect(() => {
    getShortPublishedArticles().then(res => {
      setLatestArticles(res);
    });
  }, []);

  return (
    <div>
      <CarouselWrapper>
        <CarouselContent>
          <AppCarousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
          >
            <CarouselItem>
              <CarouselImage src={require('../assets/GitDBanner.png')} alt="GitD Banner" />
              <CarouselImage $isMobile src={require('../assets/GitDBannerMobile.png')} alt="GitD Mobile Banner" />
            </CarouselItem>
          </AppCarousel>
          <Link to={`/games/${gameName}`}>
            <CarouselLink>
              Game page
            </CarouselLink>
          </Link>
        </CarouselContent>
      </CarouselWrapper>
      <Shadow />

      <ContentWrapper>
        <LatestArticles>
          <AppTitle>Latest</AppTitle>
          <ArticleGrid>
            {latestArticles.map(article => (
              <ArticleCard key={article.newsArticleId} {...article} />
            ))}
          </ArticleGrid>
        </LatestArticles>
      </ContentWrapper>
    </div>
  );
};

export default MainScreen;
